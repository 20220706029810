import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HtmlTooltip from './HtmlTooltip';
import { useSelectedCompany } from '../../hooks';
import { openSiiForm } from '../../helpers/sii-credentials-form-toggle';

const OnboardingTooltip = ({ active, children }) => {
  const selectedCompany = useSelectedCompany();
  return (
    <HtmlTooltip
      title={
        active
          ? (
            <Stack>
              <Typography>
                Este módulo está disponible solo para clientes
                que hayan ingresado su clave del SII.
              </Typography>
              <Button
                size="small"
                color="fingoWhite"
                sx={{ justifyContent: 'right' }}
                onClick={() => { openSiiForm(selectedCompany); }}
              >
                Ingresa tu clave del SII →
              </Button>
            </Stack>
          )
          : null
          }
    >
      <Grid flex={1}> {/* This <Grid/> is to allow Tooltip to work when disabled */}
        {children}
      </Grid>
    </HtmlTooltip>
  );
};

OnboardingTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default OnboardingTooltip;
