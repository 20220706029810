import React from 'react';
import Typography from '@mui/material/Typography';

const NoRowsMessage = () => (
  <Typography>
    Sin documentos
  </Typography>
);

export default NoRowsMessage;
