import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  useTheme,
  useMediaQuery,
  Collapse,
  Tooltip,
  Skeleton,
} from '@mui/material';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import CustomPagination from './CustomPagination';
import EnhancedTableHead from './Header';
import EnhancedTableToolbar from './Toolbar';
import EnhancedDetail from './Detail';
import { findProperty } from '../../helpers';

function descendingComparator(a, b, orderBy) {
  const aProperty = findProperty(a, orderBy);
  const bProperty = findProperty(b, orderBy);
  if (bProperty < aProperty) {
    return -1;
  }
  if (bProperty > aProperty) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const Table = (props) => {
  const {
    headers,
    orderDirection,
    initialOrderId,
    withSelect,
    topLegend,
    handleSelected,
    setNewDataOrder,
    data,
    pagination,
    dataToRows,
    clearSelection,
    preSelectedRows,
    controlled,
    detailHeaders,
    showDetails,
    loading,
    ...containerProps
  } = props;
  const [rows, setRows] = useState(dataToRows(data));
  const [order, setOrder] = useState(orderDirection);
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState(preSelectedRows.map((row) => row.id));
  const [selectedRows, setSelectedRows] = useState(preSelectedRows);
  const [page, setPage] = useState(controlled.isControlled ? controlled.page : 0);
  const [rowsPerPage] = useState(50);
  const [newHeaders, setNewHeaders] = useState(headers);
  const [isOpens, setIsOpens] = useState(dataToRows(data).map((row) => ({
    id: row.id,
    isOpen: false })));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  useEffect(() => {
    setRows(dataToRows(data));
    setIsOpens(dataToRows(data).map((row) => ({ id: row.id, isOpen: false })));
    // setRows(stableSort(data, getComparator(order, initialOrderId)));
    if (clearSelection) {
      setSelected([]);
      setSelectedRows([]);
    }
  }, [data, clearSelection, dataToRows]);

  const handleRequestSort = (_, property) => {
    const wasAsc = orderBy === property && order === 'asc';
    const newOrder = wasAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    if (controlled.isControlled) {
      if (newOrder === 'asc') {
        controlled.setOrderBy(property);
      } else {
        controlled.setOrderBy(`-${property}`);
      }
      return;
    }
    setNewDataOrder((oldData) => stableSort(oldData, getComparator(newOrder, property)));
  };

  useEffect(() => {
    if (orderBy) {
      setNewDataOrder((oldData) => stableSort(oldData, getComparator(order, initialOrderId)));
    }
  }, [orderBy, initialOrderId, setNewDataOrder, order]);

  useEffect(() => {
    if (!orderBy) {
      setOrderBy(initialOrderId);
    }
  }, [data, initialOrderId, orderBy]);

  useEffect(() => {
    if (isMobile) {
      setNewHeaders(headers.filter((header) => header.mobile));
    } else {
      setNewHeaders(headers);
    }
  }, [headers, isMobile]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.filter((row) => !row.nonSelectable).map((n) => n.id);
      setSelected(newSelecteds);
      setSelectedRows(rows);
      handleSelected(rows);
      return;
    }
    setSelected([]);
    setSelectedRows([]);
    handleSelected([]);
  };

  const handleSelectOne = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    const selectedRow = rows.find((row) => row.id === id);
    let newSelected = [];
    let newSelectedRows = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelectedRows.concat(selectedRows, selectedRow);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    setSelectedRows(newSelectedRows);
    handleSelected(newSelectedRows);
  };

  const handleClickRow = (id) => {
    if (isMobile) {
      const newData = [...isOpens];
      const newRow = newData.find((row) => row.id === id);
      newRow.isOpen = !newRow.isOpen;
      setIsOpens(newData);
    }
  };

  const closeAllDetails = useCallback(() => {
    setIsOpens((_prev) => _prev.map((row) => ({ ...row, isOpen: false })));
  }, []);

  useEffect(() => {
    closeAllDetails();
  }, [isMobile, closeAllDetails]);

  const handleChangePage = (event, newPage) => {
    if (controlled.isControlled) {
      controlled.setPage(newPage);
    }
    setPage(newPage);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage
    - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const rowsToShow = controlled.isControlled ? rows : rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <>
      <EnhancedTableToolbar
        topLegend={topLegend}
        numSelected={selected.length}
      />
      <TableContainer
        {...containerProps}
      >
        <MuiTable
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAll}
            onRequestSort={handleRequestSort}
            headCells={newHeaders}
            selectableRows={rows.filter((row) => !row.nonSelectable).length}
            withSelect={withSelect}
          />
          <TableBody>
            {rowsToShow
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <React.Fragment key={row.id}>
                    <TableRow
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      style={row.style}
                      selected={isItemSelected}
                    >
                      {withSelect && (
                        <TableCell padding="none">
                          <Tooltip title={row.tooltipSelect || ''}>
                            <div>
                              <Checkbox
                                onChange={(event) => handleSelectOne(event, row.id)}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                                disabled={row.nonSelectable || loading}
                                indeterminate={row.nonSelectable}
                                color="primary"
                              />
                            </div>
                          </Tooltip>
                        </TableCell>
                      )}
                      {newHeaders.map((headCell) => (
                        <TableCell
                          key={`${headCell.label},${row.id}`}
                          size={row[headCell.id].size}
                          align={row[headCell.id].align}
                        >
                          {loading ? <Skeleton variant="text" /> : row[headCell.id].component}
                        </TableCell>
                      ))}
                    </TableRow>
                    {showDetails && detailHeaders !== [] && isMobile && (
                      <TableCell
                        colSpan={newHeaders.length + 1}
                        padding="none"
                        align="center"
                        style={{ width: '60%', justify: 'center' }}
                      >
                        <ExpandMoreRounded
                          style={(isOpens.find((openRow) => (openRow.id === row.id)).isOpen) ? { transform: 'rotate(180deg)' } : {}}
                          onClick={() => handleClickRow(row.id)}
                        />
                        <Collapse in={isOpens.find((openRow) => (openRow.id === row.id)).isOpen} timeout="auto" unmountOnExit>
                          <EnhancedDetail
                            detailHeaders={detailHeaders}
                            row={row}
                          />
                        </Collapse>
                      </TableCell>
                    )}
                  </React.Fragment>
                );
              })}
            {emptyRows > 0 && rows.length > rowsPerPage && (
              <TableRow style={{ height: 53 * emptyRows, backgroundColor: 'transparent' }} />
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      { pagination && (
        <CustomPagination
          page={page}
          totalPages={
            controlled.isControlled
              ? controlled.totalPages : Math.ceil(rows.length / rowsPerPage)
            }
          onChangePage={handleChangePage}
        />
      )}
    </>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      extraDataKey: PropTypes.string,
      extraDataKey2: PropTypes.string,
      isSearchable: PropTypes.bool,
      align: PropTypes.string,
      size: PropTypes.number,
      disableOrderBy: PropTypes.bool,
      disablePadding: PropTypes.bool,
    }),
  ).isRequired,
  setNewDataOrder: PropTypes.func,
  dataToRows: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  initialOrderId: PropTypes.string,
  orderDirection: PropTypes.string,
  withSelect: PropTypes.bool,
  handleSelected: PropTypes.func,
  pagination: PropTypes.bool,
  topLegend: PropTypes.element,
  clearSelection: PropTypes.bool,
  preSelectedRows: PropTypes.arrayOf(PropTypes.shape),
  controlled: PropTypes.shape({
    isControlled: PropTypes.bool,
    setOrderBy: PropTypes.func,
    page: PropTypes.number,
    setPage: PropTypes.func,
    totalPages: PropTypes.number,
  }),
  detailHeaders: PropTypes.arrayOf(PropTypes.shape),
  showDetails: PropTypes.bool,
  loading: PropTypes.bool,
};

Table.defaultProps = {
  orderDirection: 'desc',
  withSelect: false,
  handleSelected: () => { },
  setNewDataOrder: () => { },
  initialOrderId: null,
  topLegend: null,
  pagination: true,
  clearSelection: false,
  preSelectedRows: [],
  controlled: { isControlled: false, setOrderBy: () => {}, totalPages: 0 },
  showDetails: false,
  detailHeaders: [],
  loading: false,
};

export default Table;
