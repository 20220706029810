import { Box } from '@mui/system';
import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index }) => {
  if (value !== index) return null;
  return (
    <Box>
      {children}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabPanel;
