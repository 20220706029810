import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

const FingoBankAccount = () => (
  <Stack width="100%" spacing={1} alignItems="flex-start" justifyContent="center">
    <Typography variant="body2">Nombre: Fingo SPA</Typography>
    <Typography variant="body2">Banco Santander</Typography>
    <Typography variant="body2">Rut: 77.201.556-9</Typography>
    <Typography variant="body2">Número de cuenta: 000076829241</Typography>
    <Typography variant="body2">Email: operaciones@fingo.cl</Typography>
  </Stack>
);

export default FingoBankAccount;
