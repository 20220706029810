import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from './TabPanel';

const FingoTabs = ({ tabInfo }) => {
  const [value, setValue] = useState(0);
  const handleChange = useCallback((_, newValue) => setValue(newValue), []);
  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="fingo-tabs"
          centered
          variant="fullWidth"
        >
          {tabInfo.map(({ tabName }) => (
            <Tab key={tabName} label={tabName} wrapped />
          ))}
        </Tabs>
      </Box>
      <Divider />
      {tabInfo.map(({ tabName, component: Component, componentProps }, index) => (
        <TabPanel value={value} index={index} key={tabName}>
          <Component {...componentProps} />
        </TabPanel>
      ))}
    </Box>
  );
};

FingoTabs.propTypes = {
  tabInfo: PropTypes.arrayOf(PropTypes.shape({
    tabName: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    componentProps: PropTypes.shape(),
  })).isRequired,
};

export default FingoTabs;
